@if (props() === SKELETON_TYPE.DESCRIPTION) {
<div class="first-skeleton-wrapper">

        <div class="skeleton-stepper">
              
          <div class="stepper-item">
              <div class="stepper-circle skeleton"></div>
              <div>
                  <div class="line-large skeleton"></div>
                  <div class="line-small skeleton"></div>
              </div>
          </div>

          <div class="stepper-item">
              <div class="stepper-circle skeleton"></div>
              <div>
                  <div class="line-large skeleton"></div>
                  <div class="line-small skeleton"></div>
              </div>
          </div>

      </div>        
          <div class="skeleton-form">
              <div class="input-skeleton-group">
                  <div class="line-small skeleton"></div>
                  <div class="input-skeleton skeleton"></div>
              </div>
              <div class="input-skeleton-group">
                  <div class="line-small skeleton"></div>
                  <div class="input-skeleton skeleton"></div>
              </div>
              <div class="input-skeleton-group">
                  <div class="line-small skeleton"></div>
                  <div class="input-skeleton skeleton"></div>
              </div>
              <div class="input-skeleton-group">
                  <div class="line-small skeleton"></div>
                  <div class="input-skeleton skeleton"></div>
              </div>
              <div class="input-skeleton-group">
                  <div class="line-small skeleton"></div>
                  <div class="input-skeleton skeleton"></div>
              </div>
              
          </div>
        </div>   
}@else{
        <div class="second-skeleton-wrapper">
        <div class="section-header">
            <div class="header">
                <div class="heading skeleton"></div>
                <div class="menu-icon skeleton"></div>
            </div>
        
        </div>
        <div class="table-skeleton">
        <div class="table-data-wrapper">
        
            <div class="table-row">
        
                <div class="item-list">
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                </div>
        
            </div>
            <div class="table-row">
        
                <div class="item-list">
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                </div>
        
            </div>
            <div class="table-row">
        
                <div class="item-list">
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                </div>
        
            </div>
            <div class="table-row">
        
                <div class="item-list">
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                </div>
        
            </div>
            <div class="table-row">
        
                <div class="item-list">
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                </div>
        
            </div>
            <div class="table-row">
        
                <div class="item-list">
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                </div>
        
            </div>
            <div class="table-row">
        
                <div class="item-list">
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                    <div class="item-line-large skeleton"></div>
                </div>
        
            </div>
            
           
            
            
        
        
        </div>
        </div>

        <div class="bottom-section">
            <div class="details-wrap">
                <div class="text-wrap skeleton"></div>
                <div class="text-wrap skeleton"></div>
            </div>
            <div class="btn-wrap">
                <div class="single-btn-wrap skeleton"></div>
                <div class="single-btn-wrap skeleton"></div>
                <div class="single-btn-wrap skeleton"></div>
            </div>
        </div>
    </div>
}

