import { ChangeDetectionStrategy, Component, input } from '@angular/core';
export enum SKELETON_TYPE {
  TIMELINE = 'TIMELINE',
  DESCRIPTION = 'DESCRIPTION'
}
@Component({
  selector: 'app-locations-skeleton',
  standalone: true,
  imports: [],
  templateUrl: './locations-skeleton.component.html',
  styleUrl: './locations-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsSkeletonComponent {
  props = input.required<SKELETON_TYPE>();
  SKELETON_TYPE = SKELETON_TYPE;
}
